import * as React from 'react';
import { CurrencyEnum } from 'src/core/enums';
import useStorefrontData from 'src/hooks/useStorefrontData';
import dynamic from 'next/dynamic';
import { PageContext } from '../../context';

const DropdownCurrencySwitcher = dynamic(() => import('./Dropdown'));

const GoldCurrencySwitcher = ({ anchorEl, handleClose }) => {
  const { currency, updateCurrency } = useStorefrontData();
  const currencies = [];
  Object.values(CurrencyEnum).forEach(value => {
    currencies.push(value);
  });

  const handleChangeFunc = value => {
    updateCurrency(value);
  };
  return (
    <PageContext.Consumer>
      {({ isMobileView }) => (
        <DropdownCurrencySwitcher
          isMobile={isMobileView}
          anchorEl={anchorEl}
          handleClose={handleClose}
          currency={currency}
          currencies={currencies}
          handleChange={handleChangeFunc}
        />
      )}
    </PageContext.Consumer>
  );
};

export default GoldCurrencySwitcher;
